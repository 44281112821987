.theblur {
    background: rgba(0,0,0,0.5);
    z-index:1;
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
    backdrop-filter: blur(3px);
    mask: linear-gradient(black, black, transparent);
}

.fullblur {
    background: rgb(0,0,0,0.5);
    z-index: 1010;
    backdrop-filter: blur(3px);
}

.tableResponsive {

}

.tableResponsive td {
    padding-top: 5px;
    padding-bottom: 5px;
}